export const getPictureSrc = 'https://ce-bot.gr-pro.org'

export const getLanguage = (state) => state.language.language;
export const getText = (state) => state.language.text;

export const getBalance = (state) => state.main.balance;
export const getMainList = (state) => state.main.mainList;
export const getEvent = (state) => state.main.eventPopup;

export const getOrders = (state) => state.pro.orders;
export const getOpenOrdersList = (state) => state.pro.openOrders;
export const getCouplesList = (state) => state.pro.couples;

export const getHistoryOrders = (state) => state.history.historyOrders;
export const getHistoryTransactions = (state) => state.history.historyTransactions;
export const getOrdersHistoryCVV = (state) => state.history.ordersHistoryCVV;
export const getTransactionsHistoryCVV = (state) => state.history.transactionsHistoryCVV;

export const getTheme = (state) => state.account.theme;
export const getUserId = (state) => state.account.telegramId;
export const getRefToken = (state) => state.account.refToken;
export const getAcToken = (state) => state.account.acToken;
export const getIsTwoFactor = (state) => state.account.isAuthTwoFactor;

export const getWallets = (state) => state.wallet.wallets;
export const getNetworkWallets = (state) => state.wallet.networkWallets;
export const getCurrentToken = (state) => state.wallet.currentToken;
export const getCurrentNetwork = (state) => state.wallet.currentNetwork;
export const getCurrentCurrency = (state) => state.wallet.currentCurrency;
export const getWalletById = (state, id) => state.wallet.wallets.find((item) => id && Number(item.id) === Number(id));
